import React from "react";
import styled from "styled-components";
//-----MATERIAL-----
import Grid from "@material-ui/core/Grid";

const TiledImgContainer = styled(Grid)`
  @media (min-width: 960px) {
    height: 66.4vh;
  }
`;

const TiledImg = styled(Grid)`
  position: relative;
  width: 50%;
  padding-top: 43.5%;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${(props) => props.$bgColor};
  @media (min-width: 960px) {
    padding-top: 0;
    background-color: transparent;
  }
`;

export default ({ tileOne, tileTwo, tileThree, bgColor = "transparent" }) => (
  <TiledImgContainer container direction="row-reverse">
    <TiledImg image={tileOne} />
    <TiledImg $bgColor={bgColor} />
    <TiledImg container item $bgColor={bgColor}>
      {tileThree}
    </TiledImg>
    <TiledImg image={tileTwo} />
  </TiledImgContainer>
);
