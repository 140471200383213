import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import { PaddingBox, VerticalDivider } from "../components/globals";
import { DropdownContext } from "./layout";
//-----MATERIAL-----
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

const GreyBox = styled(Box)`
  padding-bottom: 3.5em;
  &::after {
    content: "";
    display: ${(props) => (props.enabled ? "block" : "none")};
    background-color: #e3e3e3;
    opacity: 75%;
    min-height: 150px;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      margin-bottom: 1.75em;

      &::after {
        display: none;
      }
    }
  `}
`;

const TextContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 99%;

  ${({ theme }) => `
     ${theme.breakpoints.up("md")} {
       max-width: 95%;

       &::after {
        bottom: -13.5vw;
        height: 13vw;
      } 
    }
    ${theme.breakpoints.up("lg")} {
      transform: translateY(20%);

      &::after {
        bottom: -14.25vw;
        height: 14vw;
      }
    }
  `}
`;

const StyledPaddingBox = styled(PaddingBox)`
  display: flex;
  position: relative;
  ${({ theme }) => `
     ${theme.breakpoints.up("md")} {
        text-align: right;
        justify-content: flex-end;
        align-items: center;
        padding-right: 14%;
    }
  `}
`;

const ButtonNoPadding = styled(Button)`
  padding: 0;
`;

const OpaqueImg = styled(Box)`
  display: none;
  background-image: url(${(props) => props.src});
  width: 52%;
  height: 80%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 25%;
  margin-top: 7%;
  margin-right: 7%;
  ${({ theme }) => `
     ${theme.breakpoints.up("md")} {
       display: block;
    }
  `}
`;

function ServiceTextContent({ content }) {
  return (
    <TextContainer pt={{ xs: "3em", md: "0" }}>
      <Typography variant="h3" paragraph component={Box} mb={3}>
        {content.header}
      </Typography>
      {content.paragraphs.map((p, index) => (
        <Typography variant="body1" paragraph key={index}>
          <Box
            component="span"
            fontSize={{ md: "1rem" }}
            lineHeight={{ md: "19px" }}
          >
            {p}
          </Box>
        </Typography>
      ))}
    </TextContainer>
  );
}

function GetInTouchButton() {
  return (
    <DropdownContext.Consumer>
      {(menuToggles) => (
        <ButtonNoPadding onClick={menuToggles.toggleContactMenu} disableRipple>
          <Typography component="span" variant="h3">
            get in touch
          </Typography>
        </ButtonNoPadding>
      )}
    </DropdownContext.Consumer>
  );
}

export default ({ content, highlightGetInTouch, bgImage }) => {
  return (
    <GreyBox height="100%" enabled={highlightGetInTouch}>
      <StyledPaddingBox height={{ md: "66.4vh" }}>
        <ServiceTextContent content={content} />

        <OpaqueImg src={bgImage} />
      </StyledPaddingBox>

      <Hidden mdUp>
        <PaddingBox>
          <VerticalDivider />
        </PaddingBox>
      </Hidden>

      <StyledPaddingBox height={{ md: "33.6vh" }}>
        <GetInTouchButton />
      </StyledPaddingBox>
    </GreyBox>
  );
};
