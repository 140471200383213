const AboutUs = {
  header: "about us",
  paragraphs: [
    `Smith Built construction is a family-owned and operated business. No matter the size of your project, Smith Built will deliver quality work in a timely fashion.`,
    `Phillip "Jake" Smith has more than 37 years of experience in framing,roofing,project management,and general contracting. He started roofing at age 18. He started 
    his own roofing business in 1991. This led to expanding his business to include framing and general contracting. Jake has worked in multiple local developments, 
    including Castlewood, Viking, and Grouse Meadows. He is proficient in building custom homes, custom framing, additions, and remodels. Overall, Jake's 37-plus years 
    of experience means he can make your idea, no matter the size, a reality.`,
    `Also working with Jake is his son Austin. Smith Built offers nearly 60 years of combined experience between them, all at your disposal for your next construction project.`
  ],
};

const Construction = {
  header: "new construction",
  paragraphs: [
    `Smith Built isn't like other construction companies. We don't work exclusively on a limited set of blueprints. Whatever your blueprint calls for, from a first home 
    to a custom dream home away from the everyday bustle, Smith Built would be proud to handle your new construction needs`,
    `We can offer you complete contracting services, from foundation to roof. This includes custom homes on your own parcel of land. Smith Built offers full service 
    framing, roofing, siding, and project management for other services like foundations, electrical, and plumbing.`,
  ],
};
const Remodel = {
  header: "remodeling",
  paragraphs: [
    `Smith Built doesn't work only on new construction projects. We can also provide remodeling services for your existing home. Is your bathroom literally out of the 70's? 
    Does your kitchen turn meal prep into a harrowing experience? Maybe your bedroom just needs a refresh. No matter the project, Smith Built can make your home new again.`,
    `Also, don't forget your yard. We have the expertise to build exterior entertainment areas. Our services include construction of gazebos, arbors, fencing, decks, and 
    outdoor kitchens. We even offer concrete counter tops.`,
  ],
};
const Additions = {
  header: "additions",
  paragraphs: [
    `Feeling cramped? Has your family outgrown your home? Perhaps you just want some more space. That's why Smith Built offers addition services.  Perhaps you want to 
    extend your living room for better entertaining. Maybe your kitchen is getting too cramped as your family grows. Or you want the master bedroom/bathroom extended. Of 
    course, Smith Built also offers construction of new rooms for your home. If you need more space in your existing home, Smith Built are the ones to call.`,
  ],
};
const Management = {
  header: "project management",
  paragraphs: [
    `Every project needs competent management. Even the best plans can fall apart without someone to handle the little things that keep a project on time and of high quality. 
    Smith Built's owner, Jake Smith, offers more than 37 years of experience in all aspects of home construction. Smith Built can ensure your plans are approved and to code. 
    We can handle all required permits and inspections to minimize or prevent delays. We have extensive experience with general contracting. Hiring and retaining the needed 
    subcontractors for a project is well within our experience. We can also plan the budget for a project, including contingencies, and monitor the budget to minimize 
    potential cost overruns. Bring your project to Smith Built, and we'll do the rest.`,
  ],
};

export { AboutUs, Construction, Remodel, Additions, Management };
