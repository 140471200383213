import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";

const GreyPaneContainer = styled(Box)`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
`;

const GreyPane = styled(Box)`
  background-color: #e3e3e3;
  opacity: 75%;
`;

const LeftGreyPane = styled(GreyPane)`
  position: fixed;
  top: 0;
  height: 59.4vh;
  width: 100%;
  @media (min-width: 960px) {
    width: calc(50% - 4.375em);
    height: 66.4vh;
  }
`;

const RightGreyPane = styled(GreyPane)`
  display: none;
  position: fixed;
  width: 50%;
  height: 33.6vh;
  bottom: 0;
  right: 0;
  @media (min-width: 960px) {
    width: calc(50% - 4.375em);
    display: block;
  }
`;

export default (props) => (
  <GreyPaneContainer {...props}>
    <LeftGreyPane />
    <RightGreyPane />
  </GreyPaneContainer>
);
